<template>
  <navbar-component></navbar-component>
      <router-view></router-view>
      <site-footer></site-footer>
</template>

<script>
import NavbarComponent from './components/NavbarComponent.vue'
import SiteFooter from './components/SiteFooter.vue'
export default {
  name: 'App',
  components: {
    NavbarComponent,
    SiteFooter
  }
}
</script>

<style>
</style>
