<template>
    <footer class="blue-bg">
        <div class="container footer">
            <div class="columns">
                <div class="column is-4">
                    <p class="is-size-1">WebBank</p>
                    <br>
                    <p><a href="https://fdic.gov" target="_blank">Member FDIC</a> |
                        <a href="https://hud.gov" target="_blank">
                            <span>Equal Housing Lender</span>
                            <span class="icon"><i class="fa-solid fa-house-chimney"></i></span>
                        </a>
                    </p>
                    <br>
                    <p><a href="tel:18449942265" class="has-text-white">1-844-994-BANK (2265)</a></p>
                    <br>
                    <p><a href="https://www.linkedin.com/company/webbank" target="_blank">
                            <span class=" icon"><i class="fa-brands fa-linkedin-in fa-xl"></i></span>
                        </a></p>
                </div>
                <div class="column">
                    <div class="columns">
                        <div class="column">
                            <p class="is-size-4">Personal</p>
                            <p class="footer-link">
                                <router-link to="/partners">Brand Partners</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/deposit-products">Deposit Products</router-link>
                            </p>
                        </div>
                        <div class="column">
                            <p class="is-size-4">Business</p>
                            <p class="footer-link">
                                <router-link to="/partners">Brand Partners</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/specialty-finance">Specialty Finance</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/commercial-insurance">Commercial Insurance Premium Finance
                                </router-link>
                            </p>
                        </div>
                        <div class="column">
                            <p class="is-size-4">WebBank</p>
                            <p class="footer-link">
                                <router-link to="/about">About Us</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/team">Leadership Team</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/working-at-webbank">Working at WebBank</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/associations">Business Associations and Memberships</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/financial-statement">Financial Statement</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/newsroom">Newsroom</router-link>
                            </p>
                        </div>
                        <div class="column">
                            <p class="is-size-4">Policies</p>
                            <p class="footer-link">
                                <router-link to="/uigea">UIEGA</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/patriot-act">USA PATRIOT Act</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/privacy">Privacy</router-link>
                            </p>
                            <p class="footer-link">
                                <router-link to="/terms">Terms of Use</router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>
