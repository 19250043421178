<template>
    <section class="hero wb-hero">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-6">
                            <p class="title hero-title">
                                The Bank<br>Behind The Brand
                            </p>
                            <p class="subtitle hero-subtitle">
                                As the Bank Behind The Brand&reg;, we empower
                                fintech partners to drive innovation and financial inclusion.
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>
    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <br>
                    <img src="https://assets.leviathandataworks.com/webbank/wb-home1.png" />
                </div>
                <div class="column is-5">
                    <h3 class="is-size-1 has-text-primary">Strong Partnerships.<br>Great Solutions.</h3>
                    <br>
                    <p class="is-size-5">WebBank is a national issuer of consumer and small business credit products
                        through Strategic
                        Partner platforms, which include retailers, manufacturers, finance companies, software as a
                        service (“SaaS”) and financial technology (fintech) companies. We are a leading player in the
                        digital lending space, driving innovation in financial products through embedded finance with
                        Strategic Partner platforms. </p>
                </div>
            </div>
        </div>
    </section>
    <br>
    <div id="differentiators" class="blue-bg">
        <section class="section">
            <div class="container">
                <div class="has-text-centered">
                    <h3 class="is-size-1 has-text-white">KEY DIFFERENTIATORS</h3>
                </div>
                <br>
                <br>
                <div class="columns">
                    <div class="column is-4 has-text-centered">
                        <div class="box wb-box">
                            <br>
                            <br>
                            <br>
                            <span class="icon diff-icon"><i class="fa-light fa-hand-holding-hand fa-10x"></i></span>
                            <br>
                            <br>
                            <h4 class="box-title">Collaborative</h4>
                            <p class="is-size-5">We take a consultative approach that bridges
                                innovation and regulatory requirements to drive product
                                and funding solutions.</p>
                        </div>
                    </div>
                    <div class="column is-4 has-text-centered">
                        <div class="box wb-box">
                            <br>
                            <br>
                            <br>
                            <span class="icon diff-icon"><i class="fa-light fa-seedling fa-10x"></i></span>
                            <br>
                            <br>
                            <h4 class="box-title">Growth Oriented</h4>
                            <p class="is-size-5">Our entrepreneurial spirit together with our
                                institutional knowledge power growth and scale
                                with partners that range from start-ups to Fortune
                                500 companies.</p>
                        </div>
                    </div>
                    <div class="column is-4 has-text-centered">
                        <div class="box wb-box">
                            <br>
                            <br>
                            <br>
                            <span class="icon diff-icon"><i class="fa-light fa-lightbulb-on fa-10x"></i></span>
                            <br>
                            <br>
                            <h4 class="box-title">Innovative</h4>
                            <p class="is-size-5">We continuously partner with platforms to incubate
                                and launch new products through the digitization of
                                financial services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <br><br>
    </div>


    <div id="extraordinary">
        <section class="section">
            <div class="container">
                <div class="columns">
                    <div class="column is-5 is-offset-1 has-text-right">
                        <h3 class="is-size-1 has-text-primary">Extraordinary brands Partner with WebBank.</h3>
                        <br>
                        <p class="is-size-5">Our partners represent some of the most successful start-ups and the some
                            of the most recognizable brands representing a diverse mix of businesses. We work closely
                            with each of our partners to develop solutions that expand access to capital and financial
                            products. </p>
                        <br>
                        <button class="button is-primary is-large">See Our Partners</button>
                    </div>
                    <div class="column is-5">
                        <br>
                        <img src="https://assets.leviathandataworks.com/webbank/home2.png" />
                    </div>
                </div>
            </div>
        </section>
    </div>

    <br>

    <section class="section">
        <div class="container">
            <div class="columns">
                <div class="column is-5 is-offset-1">
                    <br>
                    <img src="https://assets.leviathandataworks.com/webbank/wb-home1.png" />
                </div>
                <div class="column is-5">
                    <h3 class="is-size-1 has-text-primary">Access to Capital to Fund Specialty Finance Assets</h3>
                    <br>
                    <p class="is-size-5">WebBank provides asset-based lending and other credit facilities to Strategic
                        Partners, credit funds, and other lenders with a focus on specialty finance assets. </p>
                    <br>
                    <button class="button is-primary is-large">Learn More</button>
                </div>
            </div>
        </div>
    </section>
    <br>


    <section class="hero bottom-hero">
        <div class="hero-body">
            <section class="section">
                <div class="container has-text-centered">
                    <br>
                    <p class="hero-title">
                        Not Just A Job,<br>Discover a Great Career.
                    </p>
                    <p class="hero-subtitle">
                        Work with the most talented people in our industry.<br>
                        Join us and see where new opportunities with WebBank can take you.
                    </p>
                    <br>
                    <button class="button is-large is-white">Learn More</button>
                </div>
            </section>
        </div>
    </section>
</template>

<script>
export default {

}
</script>
