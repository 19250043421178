<template>
    <section class="hero rates-hero">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-6">
                            <p class="title hero-title">
                                Rates and Fees
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>
    <br>

    <section class="section">
        <div class="container has-text-centered">
            <h2 class="blue-title">Our Current Rates</h2>

            <div class="columns is-mobile">
                <div class="column is-half has-text-centered">Product</div>
                <div class="column is-half has-text-centered">Annual Percentage Yield</div>
            </div>
        </div>
    </section>

</template>

<script>
export default {

}
</script>
