import { createWebHistory, createRouter } from "vue-router";
import HomePage from "@/views/HomePage.vue";
import AboutPage from "@/views/AboutPage.vue";
import FormsPage from "@/views/FormsPage.vue";
import RatesPage from "@/views/RatesPage.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: HomePage,
    },
    {
        path: "/about",
        name: "AboutPage",
        component: AboutPage,
    },
    {
        path: "/forms",
        name: "FormsPage",
        component: FormsPage,
    },
    {
        path: "/rates-fees",
        name: "RatesPage",
        component: RatesPage,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
